import React, { useContext } from "react";
import "../Card/Card.scss";
import "./CommentCard.scss";
import { ICommentCardProps } from "../Card/Card.types";
import CloseIcon from "@material-ui/icons/Close";
import { CommentService } from "../../services/Comments/Comment.service";
import { SidebarContext } from "../../contexts/sidebar.context";
import { TwindleComment } from "../../models/TwindleComment";

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const CommentCard = (props: ICommentCardProps) => {
    const { changed, setChanged, comments } = useContext(SidebarContext);
    const service = new CommentService();
    const cardTitle = props.title || undefined;
    const commentId = props.commentId || undefined;

    const deleteComment = () => {
        const newComment = new TwindleComment();
        newComment._id = commentId;
        service.deleteComment(newComment);
        setChanged(!changed);
    }

    return (
        <div className="card">
            <div className="card__header">
                <div className="card__title__wrapper">
                    {props.status &&
                        <span className={`card__status status status--${props.status}`} />}
                    {cardTitle &&
                        <h3 className="card__title">{cardTitle}</h3>}
                    {commentId !== undefined ?
                        <div className="card__delete_button" onClick={deleteComment}>
                            <CloseIcon />
                        </div> : <div></div>
                    }
                </div>
                {props.subtitle &&
                    <h4 className="card__subtitle">{props.subtitle}</h4>}
            </div>
            <div className="card__content">
                {props.children}
            </div>

        </div>
    )
}

export default CommentCard;