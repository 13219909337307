import React from 'react';
import { ICardProps } from './Card.types';
import './Card.scss';
import { STATUSTYPE } from '../../types/status.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Card = (props: ICardProps) => {
    const cardTitle = props.title || props.metric?.displayName || undefined;
    return (
        <div className="card">
            <div className="card__header">
                <div className="card__title__wrapper">
                    {props.status &&
                        <span className={`card__status status status--${props.status}`} />}
                    {cardTitle &&
                        <h3 className="card__title">{cardTitle}</h3>}
                    {props.metric &&
                        <h3 className="card__metric">
                            {props.metric.statusType === STATUSTYPE.DOOR ? props.metric.status : props.metric.value} {props.metric.unit}
                        </h3>}
                </div>
                {props.subtitle &&
                    <h4 className="card__subtitle">{props.subtitle}</h4>}
            </div>
            <div className="card__content">
                {props.children}
            </div>
        </div>
    )
}

export default Card;