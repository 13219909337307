//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

import { TwindleLocation } from '../services/TwindleLocation/TwindleLocation';
import { STATUS } from './status.types';

export enum CARDMETRICTYPE {
	LOCATION = 'location',
	METRIC = 'metric',
	LAYER = 'layer',
}

export interface ICardMetricProps {
	metric?: any; // change to metric interface
	layer?: any; // change to layer interface
	location?: TwindleLocation;
	status?: STATUS;
	type?: CARDMETRICTYPE;
}
