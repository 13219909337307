import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import modelConfig from '../config/model.config';
import { ISettingsContext } from '../types/context.types';
import { LocationContext } from './location.context';
import { SidebarContext } from './sidebar.context';
import { TwindleContext } from './twindle.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const SettingsContext = React.createContext({} as ISettingsContext);

export const SettingsProvider = (props: { children: ReactNode }) => {
    const [ autoRotate, setAutoRotate ] = useState(false);
    const [ showActivity, setShowActivity ] = useState(false);
    const [ hideTags, setHideTags ] = useState(false); 
    const [ cameraDistance, setCameraDistance ] = useState(modelConfig.camera.distance.floor);
    const [ showHeatMap, setShowHeatMap ] = useState(false);
    const [ heatMapData, setHeatMapData ] = useState(null);
    const [triggerCommentFilter, setTriggerCommentFilter] = useState(false);
    const [ filterStartDate, setFilterStartDate ] = useState(null);
    const [ filterEndDate, setFilterEndDate ] = useState(null);

    // Context hooks
    const { twindle } = useContext(TwindleContext);
    const { currentLocation, setCurrentLocation } = useContext(LocationContext);
    const { setSidebarOpen } = useContext(SidebarContext);

    const setStandardDate = (start, hours, minutes, seconds, millis) =>{
        let date;

        if(start){
            const weekMillis = 604800000;
            date = new Date(Date.now() - weekMillis);
        }
        else{
            date = new Date();
        }

        date.setHours(hours);
        date.setMinutes(minutes)
        date.setSeconds(seconds);
        date.setMilliseconds(millis);
        return date;
     }

    const [ startDate,  setStartDate ] = useState(setStandardDate(true, 0, 0, 0, 0));
    const [ endDate, setEndDate ] = useState(setStandardDate(false, 23, 59, 59, 999));

    const value = {
        autoRotate, setAutoRotate,
        showActivity, setShowActivity,
        hideTags, setHideTags,
        cameraDistance, setCameraDistance,
        showHeatMap, setShowHeatMap,
        startDate, setStartDate,
        endDate, setEndDate,
        heatMapData, setHeatMapData,
        triggerCommentFilter, setTriggerCommentFilter,
        filterStartDate, setFilterStartDate,
        filterEndDate, setFilterEndDate
    };

    // When showing active rooms, make the model readable
    useEffect(() => {
        if (showActivity === true) {
            setCurrentLocation(twindle.find(location => location.isTopLevel === true));
            setAutoRotate(false)
            setHideTags(false)
            setSidebarOpen(false);
        }
    }, [showActivity, twindle, setCurrentLocation, setSidebarOpen]);

    // Stop autorotate on room focus
    useEffect(() => {
        if (currentLocation.isTopLevel !== true) {
            setAutoRotate(false);
        }
    }, [currentLocation]);

    return (
        <SettingsContext.Provider value={value}>
            {props.children}
        </SettingsContext.Provider>
    )
}
