import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ISidebarContext } from '../types/context.types';
import { LocationContext } from './location.context';
import { ISidebarData, LOCATIONTAB, MAINTAB, SIDEBARROUTE } from '../types/sidebar.types';
import { SettingsContext } from './settings.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const SidebarContext = React.createContext({} as ISidebarContext);

export const SidebarProvider = (props: { children: ReactNode }) => {
    const [ sidebarOpen, setSidebarOpen ] = useState<boolean>(true);
    const [ sidebarData, setSidebarData ] = useState<ISidebarData>({} as ISidebarData);
    const [ newCommentDate, setNewCommentDate ] = useState(new Date());
    const [ comments, setComments ] = useState([]);
    const [ alerts, setAlerts ] = useState([]);
    const [ commentSaved, setCommentSaved ] = useState(false);
    const [ changed, setChanged ] = useState(false);
    const [ fetchFiltered, setFetchFiltered ] = useState(false);
    const { currentLocation } = useContext(LocationContext);
    const [ enableComments, setEnableComments ] = useState(false);

    // Initial sidebar data
    useEffect(() => {
        const initialSidebarData = {
            route: SIDEBARROUTE.MAIN,
            maintab: MAINTAB.OVERVIEW,
            locationtab: LOCATIONTAB.OVERVIEW,
        }
        setSidebarData(initialSidebarData);
    }, []);

    // Set sidebar to overview route on top location
    useEffect(() => {
        console.log(enableComments, currentLocation);
        if(currentLocation.isTopLevel) {
            if(enableComments === true){
                setSidebarData(s => s = { ...s, route: SIDEBARROUTE.COMMENT });
            }
            else{
                setSidebarData(s => s = { ...s, route: SIDEBARROUTE.MAIN });
            }
            setChanged(!changed);
        }
        else {
            setSidebarData(s => s = { ...s, route: SIDEBARROUTE.LOCATION });
            setChanged(!changed);
        }
    }, [currentLocation, enableComments]);

    useEffect(() => {
        console.log(enableComments);
        if(enableComments === false){
            console.log(comments);
        }
        else{
            console.log('-->');
        }
    }, [enableComments]);

    const value = {
        sidebarOpen, setSidebarOpen,
        sidebarData, setSidebarData,
        comments, setComments,
        changed, setChanged,
        newCommentDate, setNewCommentDate,
        commentSaved, setCommentSaved,
        alerts, setAlerts,
        fetchFiltered, setFetchFiltered,
        enableComments, setEnableComments,
    }

    return (
        <SidebarContext.Provider value={value}>
            {props.children}
        </SidebarContext.Provider>
    )
}
