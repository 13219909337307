import React, { useContext, useState } from 'react';
import apiConfig from '../../config/api.config';
import TwindleComment from '../../models/TwindleComment';
import { TwindleLocation } from '../TwindleLocation/TwindleLocation';


export class CommentService{
	comments: any[];
    url = `${apiConfig.apiUrl}/comments/`

	public fetchCommentsForLocation(location: TwindleLocation, from: number, to: number) {
	
		return new Promise((resolve, reject) => {
			fetch(this.url + `room?id=${location.id}&from=${from}&to=${to}`)
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
		
			})
			.catch(reject);
        })


	}

	public fetchAllCommentsForClient(from: number, to: number){
		return new Promise((resolve, reject) => {
			fetch(this.url + `all?from=${from}&to=${to}`)
			.then((res) => res.json())
			.then((result) => {
				resolve(result);
			})
			.catch(reject);
        });
	}

	public createComment(comment: TwindleComment){
		console.log(JSON.stringify(comment));
		fetch(this.url + 'create', {
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
			body: JSON.stringify(comment)
		}).catch((err) => console.log(err));
	}

	public deleteComment(comment: TwindleComment){
		fetch(this.url + 'delete', { 
			method: 'DELETE',
			headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
			body: JSON.stringify(comment)
		}).catch((err) => console.log(err));
	}
}