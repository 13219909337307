import { useContext, useEffect, useState } from "react";
import CommentDatepicker from "../CommentDatePicker/CommentDatePicker";
import { LocationContext } from "../../contexts/location.context";
import { SidebarContext } from "../../contexts/sidebar.context";
import { CommentService } from "../../services/Comments/Comment.service";
import TwindleComment from "../../models/TwindleComment";
import "./CommentInput.scss";
import apiConfig from "../../config/api.config";


const CommentInput = () => {

    const { newCommentDate, changed, setChanged } = useContext(SidebarContext);
    const { currentLocation } = useContext(LocationContext);
    const [ comment, setComment ] = useState(null);
    const service = new CommentService();
    let textBoxValue = "Selecteer een ruimte en datum om een opmerking te plaatsen." 


    const textAreaChanged = (event) => {
        setComment(event.target.value);
    }

    const saveComment = () => {
        const textBox = (document.getElementById("comment-input") as HTMLInputElement);
        const newComment = new TwindleComment();
        newComment.client = apiConfig.client;
        newComment.roomId = currentLocation.id;
        newComment.displayName = currentLocation.displayName;
        newComment.body = comment;
        newComment.timeStamp = newCommentDate;
        if(comment){
            service.createComment(newComment);
            textBox.placeholder = ""
            textBox.style.border = "";
            textBox.value = "";
            setComment(null);
        }
        else{
            textBox.placeholder = "Dit veld mag niet leeg worden gelaten!"
            textBox.style.border = "solid #FF0000"
        }
        setChanged(!changed);
    }
    
    return (
        <div className="form-area">
            <form>
                <div className="textarea-box">
                    <textarea id="comment-input" onChange={textAreaChanged} placeholder={textBoxValue} ></textarea>
                </div>
                <div className= "control-area">
                    <div className = "date">
                        <CommentDatepicker />
                    </div>
                    <div className="send-button" onClick={saveComment}>Plaatsen</div>
                </div>
            </form>
        </div>
    )
}

export default CommentInput;