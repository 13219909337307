import React, { useContext } from 'react';
import { ReactComponent as NextIcon } from '../../svg/next.svg';
import { ReactComponent as PresentIcon } from '../../svg/activity.svg';
import { CARDMETRICTYPE, ICardMetricProps } from '../../types/cardmetric.types';
import { LocationContext } from '../../contexts/location.context';
import { SidebarContext } from '../../contexts/sidebar.context';
// import { STATUS } from '../../types/status.types';
import './CardMetric.scss';
import { STATUS, STATUSTYPE } from '../../types/status.types';
import { SIDEBARROUTE } from '../../types/sidebar.types';
import { Tooltip } from '@material-ui/core';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const CardMetric = (props: ICardMetricProps) => {
    const { setCurrentLocation } = useContext(LocationContext);
    const { sidebarData, setSidebarData } = useContext(SidebarContext);

    const onMetricClick = () => {
        if (props.type === CARDMETRICTYPE.LOCATION && props.location) {
            setCurrentLocation(props.location);
        }
        if (props.type === CARDMETRICTYPE.METRIC && props.metric) {
            setSidebarData({ ...sidebarData, route: SIDEBARROUTE.METRIC, metric: props.metric })
        }
        if (props.type === CARDMETRICTYPE.LAYER && props.layer) {
            setSidebarData({ ...sidebarData, route: SIDEBARROUTE.LAYER, layer: props.layer })
        }
    }


    return (
        <div className="card-metric" onClick={onMetricClick}>
            {typeof props?.status !== 'undefined' ?
                <Tooltip title={`Status: ${props.status}`}>
                    <span className={`card-metric__status status status--${props.status}`}></span>
                </Tooltip>
                :
                <Tooltip title={`Geen status`}>
                    <span className={`card-metric__status status status--undefined`}></span>
                </Tooltip>
            }
            <p className="card-metric__display-name">{props.metric?.displayName || props.location?.displayName}
                {(typeof props?.metric !== 'undefined' && props.metric.status === STATUS.PRESENT) &&
                    <Tooltip title={`Status: ${props.status}`}>
                        <PresentIcon className={`card-metric__icon fill--${props.status}`} />
                    </Tooltip>
                }
            </p>
            {typeof props.metric !== "undefined" &&
                <p className="card-metric__metric">
                    {props.metric.statusType === STATUSTYPE.DOOR ? props.metric.status : props.metric.value} {props.metric.unit}
                </p>
            }
            <NextIcon className="card-metric__next-btn" />
        </div>
    )
}

export default CardMetric;