import { meshBounds, useGLTF } from 'drei';
import modelConfig from '../../config/model.config';
import { SettingsContext } from '../../contexts/settings.context';
import React, { useContext } from 'react';
import * as THREE from "three";
import h337 from "heatmap.js";

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Meshes = () => {
    const { showHeatMap, heatMapData } = useContext(SettingsContext);
    const { setShowHeatMap } = useContext(SettingsContext);

const gltfModel = useGLTF(modelConfig.path.meshes, true);
    gltfModel.scene.castShadow = true;
    gltfModel.scene.receiveShadow = true;
    const floorOverlay = gltfModel.scene.getObjectByName("Floor001")
    const apenRots = gltfModel.scene.getObjectByName("Apenrots")
    var img = new Image();
    img.src = "./models/meshes/Image_1.png"


    if(showHeatMap === true){
        img.onload = () => {
            var heatmapInstance = h337.create({
                container: img,
                width: 2048,
                height: 2048
            });
            // now generate some random data
            var points = [];
            var max = 100;

            for (var i = 0; i < 500; i++) {
                var point = {
                    x: Math.floor(Math.random() * (1700 - 515 + 1) + 515),
                    y: Math.floor(Math.random() * (1087 - 755 + 1) + 755),
                    value: 50
                };
                points.push(point)
              }

            // heatmap data format
            var data = {
                max: max,
                data: points
            };
            // set datapoints
            heatmapInstance.setData(data);
            //load texture
            const texture = new THREE.TextureLoader().load(heatmapInstance.getDataURL("image/png"));
            const material = new THREE.MeshBasicMaterial( {
                map: texture,
                transparent: true
            } );
            floorOverlay['material'] = material;
            floorOverlay['material'].needsUpdate = true;
            floorOverlay.visible = true;
            apenRots.visible = false;
        }
    }
    else if(showHeatMap === false){
        floorOverlay.visible = false;
        apenRots.visible = true;
        apenRots['needsUpdate'] = true;
    }


    gltfModel.scene.children.forEach(child => {
        if (child.type === "Object3D") {
            child.children.forEach(child => {
                child.castShadow = true;
                child.receiveShadow = true;
            })
        }
        child.castShadow = true;
        child.receiveShadow = true;
    })

    const webModel = <primitive object={gltfModel.scene} />

    return <>{webModel}</>;
}

export default Meshes;
