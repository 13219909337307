import React from 'react';
import { ReactComponent as LogoIcon } from '../../svg/logo_white.svg';
import './Logo.scss';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Logo = () => {
    return <LogoIcon width={undefined} height="10px" className="brand-logo" title="Twindle" aria-label="Twindle Logo" />
}

export default Logo;