import DatePicker from "react-datepicker";
import React, { useContext } from 'react';
import { SidebarContext } from "../../contexts/sidebar.context";
import './CommentDatePicker.scss'


const CommentDatepicker = () => {

    const { newCommentDate, setNewCommentDate } = useContext(SidebarContext);

    const setStartDate = (date) => {
        setNewCommentDate(date);
    }
    
    return (
        <div>
            <DatePicker
                selected={newCommentDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat="d MMMM, yyyy HH:mm"
            />
        </div>
    )
}

export default CommentDatepicker;