import { useContext, useEffect } from "react";
import { SettingsContext } from "../../contexts/settings.context";
import { IControlMenuTypes, PointOfInterestType } from "../../types/controlmenu.types";
import './PointsOfInterest.scss';
import {SidebarContext} from "../../contexts/sidebar.context";

const PointsOfInterest = (props: IControlMenuTypes) => {
    const { enableComments } = useContext(SidebarContext);
    const { endDate, startDate } = useContext(SettingsContext);
    const poiList = [];

    const calculatePosition = () => {
        //Offsets of thumbs for dot positioning:
        const thumbs = document.getElementsByClassName("MuiSlider-thumb");
        let start = thumbs[0] as HTMLElement;
        let end = thumbs[1] as HTMLElement;
        console.log(start.offsetLeft, end.offsetLeft);
    }

    const clearPrevious = () => {
        let docs;
        if(props.pointOfInterestType === PointOfInterestType.COMMENT){
            docs = document.getElementsByClassName("poi-comment");
        }
        if(props.pointOfInterestType === PointOfInterestType.ALERT){
            docs = document.getElementsByClassName("poi-alert");
        }
        while (docs.length > 0) {
            let doc = docs[0];
            doc.parentNode.removeChild(doc);
        }
    }

    const addOnClick = (onclick) => {
        poiList.forEach((item) => {
            item.onclick = onclick;
        });
    }

    const createDiv = (item) => {
        let div;
        div = document.createElement("div");
        div.setAttribute("id", item._id);

        if(props.pointOfInterestType === PointOfInterestType.COMMENT){
            div.setAttribute("class", "poi-comment box");
        }
        if(props.pointOfInterestType === PointOfInterestType.ALERT){
            div.setAttribute("class", "poi-alert box");
        }
        return div;
    }

    const calculatePOIs = () => {
        clearPrevious();
        if (props.list.length !== 0) {
            props.list.forEach((item, i) => {
                const doc = document.getElementById("poi-content")
                const div = createDiv(item);
                let timeSpan = (endDate.getTime() - startDate.getTime());
                let diff = new Date(item.timeStamp).getTime() - startDate.getTime();
                let percentilePos = diff / timeSpan;
                let POIPos = (458 * percentilePos) - -6;
                div.style.left = String(POIPos + 3) + "px";
                poiList.push(div);
                doc.appendChild(div);
            });
            addOnClick(props.onClick);
        }
    }

    useEffect(() => {
        clearPrevious();
    }, [enableComments]);

    useEffect(() => {
        calculatePOIs();
    }, [props.list]);

    return (
        <div id="poi-content">
        </div>
    )
}

export default PointsOfInterest;
