import { Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ILocationTagProps } from '../../types/locationtag.types';
import { SIDEBARROUTE } from '../../types/sidebar.types';
import { STATUS, STATUSTYPE } from '../../types/status.types';
import './LocationTag.scss';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const LocationTag = (props: ILocationTagProps) => {
    // Contexts
    const { twindle } = props.twindleContext;
    const { currentLocation, setCurrentLocation } = props.locationContext;
    const { setSidebarOpen, sidebarData, setSidebarData } = props.sidebarContext;
    
    const { showActivity, hideTags } = props.settingsContext;

    // States
    const [alert, setAlert] = useState(false);
    const [transparant, setTransparant] = useState(false);

    const onTagClick = () => {
        //setSidebarData({ ...sidebarData, route: SIDEBARROUTE.COMMENT });
        setCurrentLocation(props.location);
        setSidebarOpen(true);
    }

    // Alert effect hook
    useEffect(() => {
        setAlert(props.location.status === (STATUS.WARNING || STATUS.DANGER));
    }, [props.location, twindle]);

    // Transparancy effect hook
    useEffect(() => {
        if (showActivity === false) {
            setTransparant(!currentLocation.isTopLevel && currentLocation !== props.location);
        } else {
            setTransparant(true);
            const presenceLayer = props.location.layers.find(layer => layer.statusType === STATUSTYPE.PRESENCE);
            if (typeof presenceLayer !== 'undefined' && presenceLayer.status === STATUS.PRESENT) {
                setTransparant(false);
            }
        }
    }, [currentLocation, props.location, twindle, showActivity]);

    return (
        <>
            {(hideTags === false && (props.location === currentLocation) === false) && <div className="location-tag__wrapper">
                <div
                    className={`location-tag ${transparant && "location-tag--transparant"}`}
                    onClick={onTagClick}
                >
                    {alert &&
                        <Tooltip title={`Alert: ${props.location.status}`}>
                            <span className={`location-tag__alert alert--${props.location.status}`}>!</span>
                        </Tooltip>}
                    {(typeof props.location.status !== "undefined") &&
                        <Tooltip title={`Status: ${props.location.status}`}>
                            <span className={`location-tag__status status--${props.location.status}`} />
                        </Tooltip>
                    }
                    <span className="location-tag__title">{props.location.shortName || props.location.displayName}</span>
                </div>
            </div>}
        </>
    )
}

export default LocationTag;