import { useContext, useEffect } from "react";
import { LocationContext } from "../../contexts/location.context";
import { CommentService } from "../../services/Comments/Comment.service";
import { SidebarContext } from "../../contexts/sidebar.context";
import { SettingsContext } from "../../contexts/settings.context";
import CommentInput from "../CommentInput/CommentInput";
import "../Sidebar/Sidebar.scss";
import apiConfig from "../../config/api.config";
import CommentCard from "../CommentCard/CommentCard";
import TwindleComment from "../../models/TwindleComment";


const SideBarCommentView = () => {
    const { 
        startDate, 
        endDate, 
        triggerCommentFilter, 
        setTriggerCommentFilter, 
        filterStartDate, 
        filterEndDate
    } = useContext(SettingsContext);
    const { currentLocation } = useContext(LocationContext);
    const { comments, changed, setComments, fetchFiltered, setFetchFiltered } = useContext(SidebarContext);
    const service = new CommentService();

    const fetchComments = () => {
        let from;
        let to;
        if(filterEndDate && filterEndDate ) {
            from = filterStartDate.getTime();
            to = filterEndDate.getTime(); 
        }
        else {
            from = startDate.getTime();
            to = endDate.getTime();
        }
        if(currentLocation.id === apiConfig.client) {
            service.fetchAllCommentsForClient(from, to)
                .then((json) => {
                    const fetchedComments = json as TwindleComment[];
                    setComments(fetchedComments);
                }).catch(err => {
                    console.log(err);
                })
        }
        else{
            service.fetchCommentsForLocation(currentLocation, from, to)
                .then((json) => {
                    const fetchedComments = json as TwindleComment[];
                    setComments(fetchedComments);
                }).catch(err => {
                    console.log(err);
                });
        }
        setFetchFiltered(true);
    }

    useEffect(() => {
        fetchComments();
    }, [changed, startDate, endDate, triggerCommentFilter])
    

    return (
        <div className= "sidebar__comments">
            { comments.length < 1 ?
                <div className="sidebar__comments__no_comments">
                    <CommentCard commentId={undefined}>Geen opmerkingen gevonden voor deze ruimte.</CommentCard>
                </div> :
                comments.map((comment, index) => {
                    return (
                        <div key={comment._id} id={comment._id}>
                            <CommentCard commentId={comment._id} subtitle={comment.body} title={comment.displayName}>
                                {new Date(comment.timeStamp).toLocaleString('en-GB', {hour12: false})}
                            </CommentCard>
                            <br />
                        </div>
                    );
                })
            }
            <div className="sidebar__comments__comment_input">
                <CommentInput />
            </div>
        </div>

    )
}

export default SideBarCommentView;


