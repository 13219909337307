import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { SidebarContext } from '../../contexts/sidebar.context';
import './Tabs.scss';
import Tab from './Tab';


//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Tabs = (props: { children: ReactElement<typeof Tab>[] }) => {
    const [key, setKey] = useState(0);
    const { sidebarData } = useContext(SidebarContext);

    // Set tag with enabled prop active
    useEffect(() => {
        props.children.forEach((child: JSX.Element, index: number) => {
            if (child.props.enabled === true) {
                setKey(index);
            };
        });
    }, [sidebarData, props.children]);

    const calculateLabelBackgroundWidth = () => {
        const componentCount = React.Children.count(props.children);
        let width = 100 / componentCount
        return width;
    };

    const calculateLabelBackgroundTransform = () => {
        const componentCount = React.Children.count(props.children);
        let transform = key * componentCount * 100 / componentCount;
        return transform;
    };

    const selectTab = (event: React.MouseEvent<HTMLDivElement>) => {
        const htmlTarget = event.currentTarget;
        const children = htmlTarget.parentElement?.childNodes;
        children?.forEach((child: ChildNode, index: number) => {
            if (child === htmlTarget) {
                // Update state
                setKey(index);
            };
        });

    };

    return (
        <div className="tabs">
            <div className="tabs__label-container">
                <div className="tabs__tab-labels">
                    {React.Children.map(props.children, (child: JSX.Element, index: number) => (
                        <div
                            className={`tabs__tab-label ${(index === 0) ? "active" : ""}`}
                            id={child.props.id}
                            onClick={selectTab}
                            onMouseUp={child.props.customEvent}
                            style={{ width: `calc(${100 / React.Children.count(props.children)}%)` }}
                        >
                            {/* Label text */}
                            {child.props.label}
                        </div>
                    ))}
                    {/* Label background */}
                    <div className="tabs__label-background" style={{
                        width: `${calculateLabelBackgroundWidth()}%`,
                        transform: `translateX(${calculateLabelBackgroundTransform()}%)`
                    }} />
                </div>
            </div>
            <div className="tabs__tab-content">
                {/* Content */}
                {props.children[key]}
            </div>
        </div>
    );

};

export default Tabs;