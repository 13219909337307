import React, { ReactNode, useEffect, useState } from 'react';
import apiConfig from '../config/api.config';
import { IApiContext } from '../types/context.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const ApiContext = React.createContext({} as IApiContext);

export const ApiProvider = (props: { children: ReactNode }) => {
    // Api locationconfig state
    const [locationConfig, setLocationConfig] = useState();

    // Api load state
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    // Effect hooks
    // Fetch location config from api
    useEffect(() => {
        fetch(`${apiConfig.apiUrl}/locations`)
            .then(res => res.json())
            .then(result => {
                // Fetched the locations config from api
                setLoaded(true);
                setLocationConfig(result);
            }).catch(err => {
                console.log(err)
                setLoaded(true);
                setError(err);
            })
    }, []);

    const value = { locationConfig };

    if (loaded) {
        if (error) {
            return <div>{error.message}</div>
        } else {
            return <ApiContext.Provider value={value} children={props.children} />
        }
    } else {
        return <div>Loading</div>
    }
}