import React from 'react';
import { Line } from 'react-chartjs-2';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const LogChart = (props: { data: any, unit: 'hour' | 'day' }) => {
    return (
        <>
            <Line
                data={{
                    datasets: [{
                        data: props.data,
                        lineTension: 0.1,
                        borderCapStyle: 'butt',
                        backgroundColor: 'rgba(123, 156, 154,0.4)',
                        borderColor: 'rgba(123, 156, 154,1)',
                        pointRadius: 0,
                        pointBorderWidth: 0,
                        pointHoverRadius: 0,
                        pointHoverBorderWidth: 0,
                        pointHitRadius: 0,
                    }]
                }}

                options={
                    {
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                type: 'time',
                                time: {
                                    unit: props.unit
                                }
                            }]
                        }
                    }
                }

            />
        </>
    )
}

export default LogChart;