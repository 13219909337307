import { SubscriptionsOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import DatePicker from "react-datepicker";
import { SettingsContext } from '../../contexts/settings.context';
import './SliderDatePicker.scss'


const DatePickerComponent = () => {
  const { startDate, endDate } = useContext(SettingsContext);
  const { setStartDate, setEndDate, setTriggerCommentFilter } = useContext(SettingsContext);

  const changeDate = (date, hours, minutes, seconds) => {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    setTriggerCommentFilter(false);
    return date;
  }

  return (
    <div className='date-picker-wrapper' id='date-input'>
      <div className='date-picker'>
        <div className='date'>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(changeDate(date, 0, 0, 0))}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      -
      <div className='date date-input '>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(changeDate(date, 23, 59, 59))}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePickerComponent;