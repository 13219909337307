//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

import React, { useContext, useEffect, useState } from "react";
import { LocationContext } from "../../contexts/location.context";
import { SidebarContext } from "../../contexts/sidebar.context";
import { CARDMETRICTYPE } from "../../types/cardmetric.types";
import Card from "../Card/Card";
import CardMetric from "../CardMetric/CardMetric";

const SidebarLayerView = () => {
    const { sidebarData } = useContext(SidebarContext);
    const { currentLocation } = useContext(LocationContext);

    const [currentLayer, setCurrentLayer] = useState({} as any);
    const [currentLayerMetrics, setCurrentLayerMetrics] = useState([] as any[]);

    useEffect(() => {
        setCurrentLayer(currentLocation.layers.find(l => l.id === sidebarData.layer.id))
    }, [currentLocation, sidebarData]);

    useEffect(() => {
        let metrics = [];
        if (currentLayer && currentLayer.metrics) {
            currentLayer.metrics.forEach((metric_id: string) => {
                metrics.push(...currentLocation.metrics.filter(metric => metric.id === metric_id));
            })

            console.log(metrics);
            setCurrentLayerMetrics(metrics);
        }
    }, [currentLayer, currentLocation]);

    return (
        <Card status={currentLayer.status} title={currentLayer.displayName}>
            {currentLayerMetrics.map((metric: any, index: number) => (
                <CardMetric key={index} type={CARDMETRICTYPE.METRIC} metric={metric} status={metric.status} />
            ))}
        </Card>
    )
}

export default SidebarLayerView;
