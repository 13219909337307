import Slider from "@material-ui/core/Slider";
import { Tooltip, withStyles, makeStyles } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../contexts/settings.context";
import SliderDatePicker from "../SliderDatePicker/SliderDatePicker";
import { SidebarContext } from "../../contexts/sidebar.context";
import PointsOfInterest from "../PointsOfInterest/PointsOfInterest";
import { PointOfInterestType } from "../../types/controlmenu.types";
import "./ControlMenuSlider.scss";
import Alert from "../../models/Alert";
import { SIDEBARROUTE } from "../../types/sidebar.types";
import { LocationContext } from "../../contexts/location.context";
import { TwindleContext } from "../../contexts/twindle.context";
import PropTypes from 'prop-types';

const ControlMenuSlider = () => {

    const {
        startDate,
        endDate,
        filterEndDate,
        filterStartDate,
        setFilterStartDate,
        setFilterEndDate,
        triggerCommentFilter,
        setTriggerCommentFilter,
    } = useContext(SettingsContext);

    const {
        comments,
        setSidebarOpen,
        alerts,
        setAlerts,
        sidebarData,
        setSidebarData,
        fetchFiltered,
        setFetchFiltered,
        enableComments,
        setEnableComments,
    } = useContext(SidebarContext);
    const { currentLocation, setCurrentLocation } = useContext(LocationContext);
    const { twindle } = useContext(TwindleContext);
    const [value, setValue] = useState([startDate.getTime(), endDate.getTime()]);

    //remove
    const test = () =>{
        let a = new Alert();
        a._id = "test-id";
        a.body = "Warning CO2 levels too high in boardroom";
        a.metric = "CO2";
        a.roomId = "boardroom";
        a.timeStamp = startDate;
        setAlerts([a]);
    }

    function ValueLabelComponent(props) {
        const { children, open, value } = props;

        return (
          <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
          </Tooltip>
        );
      }

      ValueLabelComponent.propTypes = {
        children: PropTypes.element.isRequired,
        open: PropTypes.bool.isRequired,
        value: PropTypes.number.isRequired,
      };


    function valueText(value) {
        const dateString = new Date(value).toLocaleString('en-GB', {hour12: false});
        return `${dateString}`;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showComments = () => {
        if (enableComments === true) {
            setEnableComments(false);
            setSidebarData({ ...sidebarData, route: SIDEBARROUTE.MAIN });
        }
        else {
            test();
            setSidebarOpen(true);
            setSidebarData({ ...sidebarData, route: SIDEBARROUTE.COMMENT });
            setEnableComments(true);
        }
    }

    const clickAlertPOI = function(){
        //remove
        setEnableComments(false);

        const alert = alerts.find(object => object._id === this.id);
        const loc =  twindle.find(loc => loc.id === alert.roomId);
        setCurrentLocation(loc);
        sidebarData.metric = alert.metric;
        setSidebarData({ ...sidebarData, route: SIDEBARROUTE.METRIC });
        const card = document.getElementById('alert-message');
        card.textContent = alert.body;
    }

    const clickCommentPOI = function(){
        const selectedElements = document.getElementsByClassName("selected_comment");
        if (selectedElements.length > 0) {
            for (let element of selectedElements) {
                element.classList.remove("selected_comment");
                const card = element.getElementsByClassName("card");
                (card[0] as HTMLElement).style.border = "";
            }
        }
        const elem = document.getElementById(this.id);
        //smooth scrolling not functioning?
        elem.scrollIntoView({ behavior: "smooth" });
        elem.classList.add("selected_comment");

        const card = elem.getElementsByClassName("card");
        (card[0] as HTMLElement).style.border = "solid #808080";
    }

    const filterComments = () => {
        setFilterStartDate(new Date(value[0]));
        setFilterEndDate(new Date(value[1]));
    }

    useEffect(() => {;
        if(filterStartDate && filterEndDate){
            setTriggerCommentFilter(!triggerCommentFilter);
        }
    }, [filterEndDate]);


    useEffect(() => {
        if(startDate !== null && endDate !== null){
            setValue([Number(startDate), Number(endDate)])
        }
    }, [startDate, endDate]);

    return (
        <div className="slider-menu">
            <div className="control-menu-item">
                <SliderDatePicker />
            </div>
            <div id="slider-content" className="slider-container slider-overlay">
                <PointsOfInterest
                    onClick={clickAlertPOI}
                    pointOfInterestType={PointOfInterestType.ALERT}
                    list={alerts}
                />
                <PointsOfInterest
                    onClick={clickCommentPOI}
                    pointOfInterestType={PointOfInterestType.COMMENT}
                    list={comments}
                />
                <div className="slider">
                    <Slider
                        min={Number(startDate)}
                        max={Number(endDate)}
                        value={value}
                        onChange={handleChange}
                        onChangeCommitted={filterComments}
                        valueLabelFormat={valueText}
                        ValueLabelComponent={ValueLabelComponent}
                    />
                </div>
            </div>
            <div className="control-menu-item__comments">
                <Tooltip title="Opmerkingen">
                    <div className={`comment-button-disabled ${enableComments ? "comment-button-enabled" : ""}`} onClick={showComments}>
                        <CommentIcon />
                    </div>
                </Tooltip>
            </div>
        </div>

    )
}

export default ControlMenuSlider;
