import React, { useContext } from 'react';
import { SIDEBARROUTE } from '../../types/sidebar.types';
import { ReactComponent as CloseIcon } from '../../svg/close.svg';
import { ReactComponent as PreviousIcon } from '../../svg/previous.svg';
import { SidebarContext } from '../../contexts/sidebar.context';
import { LocationContext } from '../../contexts/location.context';
import { TwindleContext } from '../../contexts/twindle.context';
import { Tooltip } from '@material-ui/core';
import './Sidebar.scss';

// Sidebar views
import SidebarViews from '../SidebarViews/SidebarViews';
import SidebarView from '../SidebarViews/SidebarView';
import SidebarMainView from '../SidebarViews/SidebarMainView';
import SidebarLocationView from '../SidebarViews/SidebarLocationView';
import SidebarMetricView from '../SidebarViews/SidebarMetricView';
import SidebarLayerView from '../SidebarViews/SidebarLayerView';
import SideBarCommentView from '../SidebarViews/SidebarCommentView';
import { SettingsContext } from '../../contexts/settings.context';



//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Sidebar = () => {
    const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);
    const { sidebarData, setSidebarData } = useContext(SidebarContext);
    const { setEnableComments } = useContext(SidebarContext);
    const { currentLocation, setCurrentLocation } = useContext(LocationContext);
    const { twindle } = useContext(TwindleContext);

    const closeSidebar = () => {
        setSidebarOpen(false);
        setEnableComments(false);
        setSidebarData({ ...sidebarData, });
        setCurrentLocation(twindle.find(location => location.isTopLevel === true));
    }

    const backToOverview = () => {
        setSidebarData({ ...sidebarData, route: SIDEBARROUTE.LOCATION });
    }

    return (
        <div className={`sidebar ${sidebarOpen && 'sidebar--active'}`}>
            <CloseIcon className="sidebar__close-btn" onClick={closeSidebar} />
            <div className="sidebar__header">
                <div className="sidebar__title__wrapper">
                    {currentLocation.status &&
                        <Tooltip title={`Status: ${currentLocation.status}`}>
                            <span className={`sidebar__status status status--${currentLocation.status}`}></span>
                        </Tooltip>
                    }
                    <h1 className="sidebar__title">{currentLocation.displayName}</h1>
                </div>
                <p className="sidebar__description">
                    {sidebarData.description}
                </p>
                {(sidebarData.route === SIDEBARROUTE.METRIC || sidebarData.route === SIDEBARROUTE.LAYER) &&
                    <p className="sidebar__back-link" onClick={backToOverview}><PreviousIcon />Terug naar het overzicht</p>}
            </div>
            <div className="sidebar__content">
                <SidebarViews>
                    <SidebarView id={SIDEBARROUTE.MAIN} component={<SidebarMainView />} />
                    <SidebarView id={SIDEBARROUTE.LOCATION} component={<SidebarLocationView />} />
                    <SidebarView id={SIDEBARROUTE.METRIC} component={<SidebarMetricView />} />
                    <SidebarView id={SIDEBARROUTE.LAYER} component={<SidebarLayerView />} />
                    <SidebarView id={SIDEBARROUTE.COMMENT} component={<SideBarCommentView />} />
                </SidebarViews>
            </div>
        </div>
    )
}

export default Sidebar;
