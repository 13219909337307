import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { TwindleContext } from './twindle.context';
import { ILocationContext } from '../types/context.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const LocationContext = React.createContext({} as ILocationContext);

export const LocationProvider = (props: { children: ReactNode }) => {
    const { twindle } = useContext(TwindleContext);
    const topLocation = twindle.find(location => location.isTopLevel === true);
    const [currentLocation, setCurrentLocation] = useState(topLocation);

    const value = {
        currentLocation, setCurrentLocation,
    };

    if (typeof topLocation === "undefined") {
        return <div>No toplocation found in API config</div>
    } else {
        return <LocationContext.Provider value={value} children={props.children} />
    }
}