
export interface IControlMenuTypes {
    onClick: Function;
    pointOfInterestType: PointOfInterestType;
    list: any[];
}

export enum PointOfInterestType{
    COMMENT,
    ALERT
}