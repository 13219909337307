import React, { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../contexts/location.context';
import { SidebarContext } from '../../contexts/sidebar.context';
// import { CARDMETRICTYPE } from '../../types/cardmetric.types';
// import { LOCATIONTAB } from '../../types/sidebar.types';
import Card from '../Card/Card';
import LogChart from '../LogChart/LogChart';
// import CardMetric from '../CardMetric/CardMetric';
import Tab from '../Tabs/Tab';
import Tabs from '../Tabs/Tabs';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SidebarMetricView = () => {
    const { sidebarData } = useContext(SidebarContext);
    const { currentLocation } = useContext(LocationContext);

    const [currentMetricDayLog, setCurrentMetricDayLog] = useState({ log: [] });
    const [currentMetricWeekLog, setCurrentMetricWeekLog] = useState({ log: [] });

    useEffect(() => {
        const dayLog = currentLocation.logs.day.find((log: any) => (log.metric === sidebarData.metric.id) && (log.deviceName === sidebarData.metric.deviceName))
        const weekLog = currentLocation.logs.week.find((log: any) => (log.metric === sidebarData.metric.id) && (log.deviceName === sidebarData.metric.deviceName))

        setCurrentMetricDayLog(dayLog);
        setCurrentMetricWeekLog(weekLog);
    }, [sidebarData, currentLocation]);

    return (
        <div>
        <Card title={sidebarData.metric.displayName} metric={sidebarData.metric} status={sidebarData.metric.status}>
            <Tabs>
                <Tab id="day" label="D">
                    <LogChart data={currentMetricDayLog?.log} unit={'hour'} />
                </Tab>
                <Tab id="week" label="W">
                    <LogChart data={currentMetricWeekLog?.log} unit={'day'} />
                </Tab>
            </Tabs>
        </Card>
        <br/> 
        <Card>
            <div id="alert-message">
                No current alerts for this metric.
            </div>
        </Card>

        </div>
    )
}

export default SidebarMetricView;