//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const apiConfig = {
    // apiUrl: "https://api-bigtop.twindle.io",
    apiUrl: "https://v3-backend.twindle.handpickedlabs.nl",
    client: 'big_top'
}

export default apiConfig;
