//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const modelConfig = {
	path: {
		meshes: './models/meshes/twindle-bigtop.gltf',
		rooms: './models/rooms/twindle-bigtop.gltf',
	},
	tagHeight: 1,
	camera: {
        distance: {
			max: 5,
			min: 25,
            default: 15,
            floor: 15,
            room: 5,
        },
        clamp: {
            default: Math.PI / 4,
            far: Math.PI / 3.5,
            floor: Math.PI / 4,
            room: Math.PI / 10,
        },
    },
};

export default modelConfig;
