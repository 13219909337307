import React, { useContext, useEffect, useState } from 'react';
import { TwindleContext } from '../../contexts/twindle.context';
import { SidebarContext } from '../../contexts/sidebar.context';
import { TwindleLocation } from '../../services/TwindleLocation/TwindleLocation';
import { STATUS } from '../../types/status.types';
import { MAINTAB } from '../../types/sidebar.types';
import Card from '../Card/Card';
import Tab from '../Tabs/Tab';
import Tabs from '../Tabs/Tabs';
import CardMetric from '../CardMetric/CardMetric';
import { CARDMETRICTYPE } from '../../types/cardmetric.types';
import { SettingsContext } from '../../contexts/settings.context';
import SideBarCommentView from './SidebarCommentView';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SidebarMainView = () => {
    // Contexts
    const { sidebarData, setSidebarData } = useContext(SidebarContext);
    const { enableComments } = useContext(SidebarContext);
    const { twindle } = useContext(TwindleContext);

    // States
    const [notifications, setNotifications] = useState<TwindleLocation[]>([])
    const [allLocations, setAllLocations] = useState<TwindleLocation[]>([])

    // Enabled tab
    const tabIsNotifications = sidebarData.maintab === MAINTAB.NOTIFICATIONS;
    const tabIsLocations = sidebarData.maintab === MAINTAB.LOCATIONS;

    // Save selected main tab
    const selectMainTab = (event: React.MouseEvent) => {
        const selectedTab = event.currentTarget.id as MAINTAB;
        setSidebarData({ ...sidebarData, maintab: selectedTab })
    }

    useEffect(() => {
        const withoutTop = twindle.filter(location => !location.isTopLevel);

        const dangerLocations = withoutTop.filter(location => location.status === STATUS.DANGER);
        const warningLocations = withoutTop.filter(location => location.status === STATUS.WARNING);
        const okLocations = withoutTop.filter(location => location.status === STATUS.OK);

        const otherLocations = withoutTop.filter(location => {
            if (dangerLocations.includes(location)) return undefined;
            if (warningLocations.includes(location)) return undefined;
            if (okLocations.includes(location)) return undefined;
            else return location;
        })

        setNotifications([...dangerLocations, ...warningLocations]);
        setAllLocations([...dangerLocations, ...warningLocations, ...okLocations, ...otherLocations]);
    }, [twindle]);

    return (
        <Tabs>
            <Tab
                id={MAINTAB.NOTIFICATIONS}
                label="Meldingen"
                enabled={tabIsNotifications}
                customEvent={selectMainTab}>
                <Card>
                    {notifications.length !== 0 ? notifications.map(location => (
                        <CardMetric
                            key={location.id}
                            type={CARDMETRICTYPE.LOCATION}
                            location={location}
                            status={location.status} />
                    )) : <div>Geen meldingen</div>}
                </Card>
            </Tab>
            <Tab
                id={MAINTAB.LOCATIONS}
                label="Alle ruimtes"
                enabled={tabIsLocations}
                customEvent={selectMainTab}>
                <Card>
                    {allLocations.length !== 0 ? allLocations.map(location => (
                        <CardMetric
                            key={location.id}
                            type={CARDMETRICTYPE.LOCATION}
                            location={location}
                            status={location.status} />
                    )) : <div>Geen locaties</div>}
                </Card>
            </Tab>
        </Tabs>
    )

}

export default SidebarMainView;
